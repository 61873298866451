exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cookies-js": () => import("./../../../src/pages/cookies.js" /* webpackChunkName: "component---src-pages-cookies-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jobs-js": () => import("./../../../src/pages/jobs.js" /* webpackChunkName: "component---src-pages-jobs-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-processes-assembly-and-delivery-js": () => import("./../../../src/pages/processes/assembly-and-delivery.js" /* webpackChunkName: "component---src-pages-processes-assembly-and-delivery-js" */),
  "component---src-pages-processes-brake-presses-js": () => import("./../../../src/pages/processes/brake-presses.js" /* webpackChunkName: "component---src-pages-processes-brake-presses-js" */),
  "component---src-pages-processes-design-support-js": () => import("./../../../src/pages/processes/design-support.js" /* webpackChunkName: "component---src-pages-processes-design-support-js" */),
  "component---src-pages-processes-fabrication-js": () => import("./../../../src/pages/processes/fabrication.js" /* webpackChunkName: "component---src-pages-processes-fabrication-js" */),
  "component---src-pages-processes-laser-cutting-js": () => import("./../../../src/pages/processes/laser-cutting.js" /* webpackChunkName: "component---src-pages-processes-laser-cutting-js" */),
  "component---src-pages-processes-machining-js": () => import("./../../../src/pages/processes/machining.js" /* webpackChunkName: "component---src-pages-processes-machining-js" */),
  "component---src-pages-processes-manual-welding-js": () => import("./../../../src/pages/processes/manual-welding.js" /* webpackChunkName: "component---src-pages-processes-manual-welding-js" */),
  "component---src-pages-processes-quality-js": () => import("./../../../src/pages/processes/quality.js" /* webpackChunkName: "component---src-pages-processes-quality-js" */),
  "component---src-pages-processes-robot-welding-js": () => import("./../../../src/pages/processes/robot-welding.js" /* webpackChunkName: "component---src-pages-processes-robot-welding-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-thanks-js": () => import("./../../../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */),
  "component---src-templates-jobs-js": () => import("./../../../src/templates/jobs.js" /* webpackChunkName: "component---src-templates-jobs-js" */),
  "component---src-templates-news-js": () => import("./../../../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */)
}

